// accepts a timestamp string (e.g. "1:12:16") and converts it to seconds.
function parseTimestamp(timestamp){
    // split into separate numbers, parse to int, and reverse the order to get the least significant value first.
    const split = timestamp.split(":").map(str => parseInt(str)).reverse();

    let seconds = 0;

    // seconds
    if (split[0] && !isNaN(split[0])){
        seconds += split[0];
    }
    // minutes
    if (split[1] && !isNaN(split[1])){
        seconds += split[1] * 60;
    }
    // hours
    if (split[2] && !isNaN(split[2])){
        seconds += split[2] * 60 * 60;
    }
    // days
    if (split[3] && !isNaN(split[3])){
        seconds += split[3] * 60 * 60 * 24;
    }

    return seconds;
}

export { parseTimestamp }