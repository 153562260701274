function choosePillColor(title) {
    switch (title) {
        case "Algemeen":
            return "bg-white text-black"
        case "Verdedigen":
            return "bg-pill-light_blue text-black"
        case "Aanvallen":
            return "bg-pill-green text-black"
        case "Verdedigen - Aanvallen":
        case "Verdedigen -> Aanvallen":
            return "bg-pill-yellow text-black"
        case "Aanvallen - Verdedigen":        
        case "Aanvallen -> Verdedigen":
            return "bg-pill-pink text-black"
        default:
            return "bg-white"
    }
}

export { choosePillColor };