<template>
  <div class="mb-10">
    <div class="">
      <div v-if="thumbnail" class="relative">
        <Video
          :video-url="videoUrl"
          :thumbnail="thumbnail"
          :key="videoKey"
          @video-ended="refreshHack += 1"
        />
        <!-- <img  class="transition duration-300 transform object-cover group-hover:-translate-y-1 group-hover:scale-105" :src="thumbnail" alt="review-thumbnail">
                <div class="absolute inset-x-0 bottom-0 h-20 bg-gradient-to-b from-transparent to-black"></div> -->
      </div>
    </div>
    <div class="mb-6 bg-gray-900 p-4 rounded-b">
      <div class="flex items-center cursor-default">
        <button
          class="font-medium inline-block text-white cursor-default bg-gradient-to-r from-gradient-orange text-xs px-2 to-gradient-red rounded"
          style="height: 34px"
        >
          {{ highlight.timestamp }}
        </button>
        <div
          class="ml-4 flex items-center float-right bg-button-green text-white rounded-lg px-3 py-2 text-black inline-block text-xs font-medium transition duration-300 ease-in-out"
          v-bind:class="good_rating ? 'bg-button-green' : 'bg-button-red'"
          style="height: 34px"
        >
          <svg
            v-if="good_rating"
            class="w-4 h-3"
            aria-hidden="true"
            focusable="false"
            data-icon="thumbs-up"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
            ></path>
          </svg>
          <svg
            v-else
            class="w-4 h-3"
            aria-hidden="true"
            focusable="false"
            data-icon="thumbs-down"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z"
            ></path>
          </svg>
        </div>
        <a
          class="mx-4 bg-black text-gray-200 rounded-lg px-3 py-2 inline-block text-xs font-medium transition duration-200"
        >
          <i
            class="w-2 h-2 rounded-full inline-block mr-3 transition duration-200 ease-in-out"
            v-bind:class="choosePillColor(highlight.title)"
          ></i
          >{{ highlight.title }}
        </a>
      </div>
      <p class="bg-black rounded-md p-4 text-sm text-gray-100 mt-4">
        {{ highlight.description }}
      </p>
    </div>
  </div>
</template>

<script>
import { choosePillColor } from "@/helpers/ColorHelper";
import Video from "@/components/Video";
import { parseTimestamp } from "@/helpers/TimestampParser";

export default {
  data() {
    return {
      // refresh hack: update the key when the video stops playing, resulting in a component refresh (and
      // reloading of videoplayer inside the component), and show of highlight thumbnail
      refreshHack: 0
    };
  },
  props: {
    highlight: Object,
    video: Object
  },
  components: {
    Video
  },
  methods: {
    choosePillColor
  },
  computed: {
    videoUrl () {
      if (this.highlight?.fragment) {
        return this.highlight?.fragment
      }
      return this.video.path + this.url_append
    },
    highlight_seconds() {
      return parseTimestamp(this.highlight.timestamp);
    },
    url_append() {
      const start = this.highlight_seconds - 1;
      const end = this.highlight_seconds + 8;
      return `#t=${start},${end}`;
    },
    thumbnail: function() {
      return this.highlight.image;
    },
    good_rating: function() {
      return this.highlight.rating === "+";
    },
    videoKey() {
      return this.video.id + " " + this.refreshHack;
    }
  }
};
</script>
